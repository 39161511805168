"use client";
import dynamic from "next/dynamic";
import { Flex, Text } from "@mantine/core";
import CoreButton from "@/components/core-components/core-button/CoreButton";
const Footer = dynamic(() => import("@/components/layout/footer/Footer"));
// import Link from "next/link";

export default function NotFound() {
  return (
    <div>
      <Flex
        justify="center"
        align="center"
        direction="column"
        gap={20}
        bg="background.0"
        style={{ height: "75vh" }}
      >
        {/* <Box> */}
        <Text c="white">404 | page not found</Text>
        <a href="/">
          <CoreButton title="Go to Home" variant="primary" />
        </a>
        {/* </Box> */}
      </Flex>
      <Footer />
    </div>
  );
}
